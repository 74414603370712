import React from 'react';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class Profile extends React.Component{

  

    render(){

        return(
            <>
            
            <img src="/Will.jpg" id = "profile-image" className="rounded-circle mt-4" alt="Cinque Terre" width="304" height="236" /> 
        

            <FontAwesomeIcon icon="fa-html5" />

            <div class="card mt-3">
  <div class="card-body">
    Hello all!<p/>

    Let me introduce myself, my name is Will McGill and I am a Web Developer, Digital Marketer and graduate of Awesome Inc.'s Web Developer Bootcamp. <p/>

    {/* I am a graduate of a Web Development Bootcamp at Awesome Inc. in Lexington, KY and a Full Stack Developer. <p/> */}

    The Web Developer Bootcamp was a 3-month, full-time training program for aspiring web developers. The curriculum was designed in collaboration with local technology employers to prepare individuals to dive into a career in web development. The intense training program is designed to take students from the basics of programming to competency in building modern web applications. It includes over 500 hours of hands-on training, gaining experience while building multiple software projects in an Agile environment. <p/>

    I am proficient in Javascript, HTML, CSS, PHP, and Python, as well as the React, Vue, and Laravel frameworks. <p/>

    Expounding on my development skills.  I also have close to 5 years Digital Marketing experience.  I have knowledge of SEO, SEM, and many other types of Digital Marketing tactics. <p/>

    Oh yes, I also love my siberian husky Mishka and enjoy any outdoor activity.  Hiking and rock climbing are passions of mine. <p/>

    <a href="/WillMcGillResume.pdf" download><button type="button" class="btn btn-primary" >Download My Resume</button></a>

  </div>
</div>
            </>
        )
    }
}

export default Profile